/* styles/tailwind.css */
@import './styles/globals.css';
@import './scss/ThemeScss';
@import './components/common/Common.scss';

$base-letter-spacing: normal;

:root {
	--banner-height: 60px;
}

html {

}

body {
	margin: 0;
	padding: 0;
	font-family: var(--font-inter), sans-serif;
	font-optical-sizing: auto;
	font-size: 1.5rem;
	color: $whiteColor;
	line-height: 1.6rem;
	background: $bodyBg;
	-webkit-font-smoothing: antialiased;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	box-sizing: border-box;
}

h1,
h2,
h3 {
	letter-spacing: $base-letter-spacing;
	position: relative;
}

p {
	letter-spacing: $base-letter-spacing;
	line-height: 1.5rem;
}

a {
	text-decoration: none;
	transition: all 0.2s ease-out;
	color: $linkColorOther;
}

a:hover {
	color: $whiteColor;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.w-100 {
	width: 100%;
}

h5 {
	font-weight: 600;
	font-size: 1.1rem;
	color: $whiteColor;
	margin: 0.75rem 0 0.5rem;
	position: relative;
}

/******** text filed ************/
.textField {
	width: 100%;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-15 {
	margin-top: 15px;
}

button.cancel-button {
	color: $whiteColor;
	border-radius: 6px;
}

button.square_button {
	height: 38px;
	background: #2196F3;
	color: #fff;
	border-radius: 6px;
	line-height: inherit;
	text-transform: none;
	padding: 0 2.5rem;
	cursor: pointer;
	
	&:hover {
		background: #1D639B;
	}
	
	&:disabled {
		color: #fff;
		opacity: 0.5;
	}
}

button.square_button {
	height: 38px;
	background: #2196F3;
	color: #fff;
	border-radius: 6px;
	line-height: inherit;
	text-transform: none;
	padding: 0 2.5rem;
	cursor: pointer;
	
	&:hover {
		background: #1D639B;
	}
	
	&:disabled {
		color: #fff;
		opacity: 0.5;
	}
}

button.yellow-bg-button {
	height: 38px;
	color: #fff;
	font-size: 0.9rem;
	background: #4e4bde;
	border-radius: 2.5rem;
	line-height: inherit;
	text-transform: none;
	padding: 0 2.5rem;
	cursor: pointer;

	&:hover {
		background: #2b2972;
	}

	&:disabled {
		color: #fff;
		opacity: 0.5;
	}
}

.newsreel-section {
	margin-top: 2rem;
	padding: 1.25rem 2rem;
	position: relative;
	background: #1e1d47;
	border-radius: 1.5rem;
	max-width: 600px;
}

.post-group {
	div {
		float: right;
	}
}

.Mui-selected {
	color: #fff !important;
}

@media (max-width: 1024px) {
	.intercom-lightweight-app-launcher {
		bottom: 90px !important;
	}
}