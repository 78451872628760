@import 'ThemeScss';

.custom-checkBox {
	display: block;
	width: 100%;

	.MuiCheckbox-root {
		color: $whiteColor !important;
	}

	.Mui-checked {
		color: $linkColorOther !important;
	}
}

.custom-textBox {
	.MuiFilledInput-root {
		color: $whiteColor;
		background-color: $blackColor;
		border: 1px solid #746064;
		border-radius: 8px;

		&:hover,
		&:focus {
			background-color: $blackColor;
		}

		input {
			padding: 12.5px 12px;
		}
	}
}

.light-theme {
	input {
		background-color: $secondaryColor;
		color: #fff;
	}
}

.blue {
	input {
		padding: 8px 16px;
		background-color: #2e2c6a;
		border: 1px solid #5553da;
		border-radius: 0.5rem;
		color: #fff;
	}
}

.blue-select {
	input {
		padding: 8px 16px;
		background-color: #2e2c6a;
		border-radius: 0.5rem;
		color: #fff;
	}
}

.chip_select_box.blue-select {
	background-color: #2e2c6a;
	border: 1px solid #5553da;
	border-radius: 0.5rem;
	color: #fff;
}

.autoComplete-list {
	background-color: $blackColor !important;

	ul {
		display: flex;
		width: 100%;
		justify-content: flex-start;
		flex-direction: column;

		li {
			width: 100%;
			color: $whiteColor;
			margin-right: 0;
			font-size: 13px;
			letter-spacing: normal;
			line-height: 25px;
			padding: 0.5rem;
			font-weight: normal;
			min-height: 40px !important;

			&:hover {
				background-color: rgb(34, 34, 34) !important;
			}

			&.Mui-focused {
				background-color: rgb(34, 34, 34);
			}
		}
	}

	.MuiAutocomplete-noOptions {
		color: aliceblue !important;
		font-size: 15px;
	}
}

.infinite-scroll-component {
	overflow: hidden !important;
}
